body {
    font-family: 'Open Sans', 'Arial', sans-serif !important;
}
.bg-gsh {
    background-color: #95c01f !important;
}
.btn-svg{
    background-size: 38px 38px;
    background-position: center;
    background-repeat: no-repeat;
}
.btn-svg.icon{
    height: 38px;
    width: 38px;
}
.btn-svg.icon.sm{
    height: 1.2rem;
    width: 1.2rem;
}
.btn-svg.sm{
    background-size: 1.2rem 1.2rem;
}
.btn-svg.icon.lg{
    height: 48px;
    width: 48px;
}
.search-products{
    background-image: url('/../icon/search-24px.svg');
}
.search-products:hover{
    background-image: url('/../icon/search-24px-active.svg');
}
.show-image{
    background-image: url('/../icon/image-24px.svg');
}
.show-image.toggle, .show-image:hover{
    background-image: url('/../icon/image-24px-active.svg');
}
.btn-svg.lg{
    background-size: 48px 48px;
}
.btn-gsh{
    color: #fff;
    background-color: #95c01f;
    border-color: #95c01f;
}
.btn-gsh:hover{
    color: #fff;
    background-color: #72a133;
    border-color: #587c27;
}
.btn-outline-gsh{
    color: #95c01f;
    border-color: #95c01f;
}
.btn-outline-gsh:hover{
    color: #fff;
    background-color: #95c01f;
    border-color: #95c01f;
}
.search-wrapper > #search-results{
    display: none;
    z-index: 1;
    top: 38px;
}
.search-wrapper > #search:focus + #search-results, #search-results:hover{
    display: block;
}
#search-results{
    max-height: 491px;
    overflow-x: hidden;
}
.slow-expand{
    transition: height 0.15s ease-out;
}
.pointer{
    cursor: pointer;
}
.fixed-modal{
    display: block;
    opacity: 1;
}
.fixed-modal .modal-body{
    min-height:200px;
    max-height: 50vh;
    overflow-y: scroll;
}
.show-cart{
    width: 30px;
    transition-timing-function: ease;
    transition: 0.2s;
    position: fixed;
    right: 0 
}
.show-cart:hover{
    width: 50px;
}
.reload-icon::before{
    content: '↺';
}
.w-33 {
    width: 33%!important;
}
.check{
    margin-top: -4px;
}
.sidebar-shadow{
    box-shadow: inset -1px 0px 0 rgba(0, 0, 0, .1);
}
#sidebar.show .sidebar-shadow{
    box-shadow: inset -1px -1px 0 rgba(0, 0, 0, .1);
}
#sidebar.show, #sidebar.collapsing{
    position:absolute;
}
.sidebar .nav-list{
    padding-bottom: 1.5rem;
}
.sidebar.navbar-collapse.show .mini-menu{
    border:1px solid rgba(0,0,0,.1);
}
#sidebar{
    position:relative;
    height:inherit;
    width:inherit;
    z-index:100;
    min-width:215px;
    max-width:215px;
}
#sidebar-toggle::before{
    content: "+";
}
#sidebar-toggle{
    transform: rotate(45deg);
    margin-top:-0.875rem;
    margin-left:-0.875rem;
    width: 1.75rem;
    height: 1.75rem;
    padding:0px;
    line-height: 1.5rem;
    z-index:200;
}
.flag-icon-öv{
    display:inline-block;
    border:1px solid rgba(0,0,0,.1);
    font-size:0.7rem;
    width:24px !important;
}
.icon-flag{
    vertical-align: middle;
    height:1rem;
}
.ej-delbar:hover::before{
    display:block;
    background-color:#6c757d;
    position:absolute;
    z-index:100;
    content:"Ej Delbar";
}
.icon-custom.small > img{
    height: 1.2rem;
}
.icon-custom > img{
    top:0;
    height: 2rem;
    position: relative;
    object-fit: scale-down;
}
.icon-custom-round{
    font-style: normal;
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    padding:0px;
    line-height: 1.75rem;
    font-size:1.25rem;
    z-index:200;
    color: #fff;
    text-align:center;
    border-radius: 50%;
}
.icon-custom-round > img{
    top:0;
    margin-top:-0.4rem;
    width: 1.1rem;
    height: 1.1rem;
    margin-left:auto;
    margin-right:auto;
    position: relative;
    object-fit: scale-down;
}
.icon-accept{
    display:inline-block;
    background-image:url('/../icon/baseline-done-24px.svg');
    background-size:17.59px;
    background-repeat:no-repeat;
    background-position:center;
}
.icon-custom-round.icon-accept{
    background-color: #95c01f;
    border-color: #95c01f;
}
.icon-custom-round.icon-deny{
    background-color: #e2e6ea;
}
.icon-deny{
    display:inline-block;
    background-image:url('/../icon/baseline-done-24px.svg');
    background-size:17.59px;
    background-repeat:no-repeat;
    background-position:center;
}
.sidebar .nav-item .nav-link{
    height:1.75rem;
    line-height:1.75rem;
    padding-top: 0;
    padding-bottom: 0;
    color: #444;
}
.sidebar .nav-item .nav-link:hover{
    text-decoration: underline;
    color: black;
}
.sidebar .nav-item .nav-link.active{
    font-weight:bold;
    color:#333;
}
.top-navbar-spacer{
    width:215px;
}
.top-navbar .nav > .nav-item > .nav-link{
    border-bottom: 2px solid rgba(0,0,0,0);
}
.top-navbar .nav > .nav-item > .nav-link.active{
    border-bottom: 2px solid #95c01f;
}
.top-navbar .nav > .nav-item{
    padding: 0rem 0.5rem;
}
.top-navbar .nav > .nav-item > .nav-link{
    padding: 0.5rem 0.5rem;
}
.top-navbar .nav > .nav-item > .nav-link:hover{
    color: #6c757d !important;
}
.cart-text{
    text-align:right;
    max-width: 170px;
}
.nav-icon{
    width:3rem;
    height:3rem;
}
.cover-height{
    min-height:80%;
    min-height:calc(100vh - 239px);
    background-color:#f3f3f3;
}
.collapse-button{
    background-image: url('/../icon/keyboard_arrow_down-24px.svg');
    width:19px;
}
.collapse-button.collapsed{
    background-image: url('/../icon/keyboard_arrow_up-24px.svg');
    width:19px;
}
input.amountform.btn{
    width:56px;
}
.amountform.dropdown-toggle{
    width:115px;
}
input.amountform[type="number"].is-invalid{
    padding-right: 26px;
}
input.amountform.btn-danger{
    width:26px;
}
.bg-tablesecondary{
    background-color: rgba(0, 0, 0, 0.05);
}
.footer{
    box-shadow: inset 0px 1px 0 rgba(0, 0, 0, .1);
}
span.small{
    font-size: 0.8rem;
}
i.icon{
    display:inline-block;
    font-style: normal;
    width:13px;
    max-width:13px;
}
i.icon.rotate{
    transform: rotate(45deg);
}
.amountform.amount{
    max-width:60px;
}
#meddelande.dropdown-toggle::after{
    display:none;
}
.card .min-height{
    height:151px;
}
.react-datepicker__input-container, .react-datepicker-wrapper{
    width: 100%;
}
.disabled-delivery{
    color: #ffc107 !important;
}
.disabled-delivery::before{
    transform:translate(-50%, -100%);
    padding: 0.125rem 0.25rem;
    border:1px solid rgba(0,0,0,.20);
    background-color:white;
    z-index: 100;
    position:absolute;
    display:none;
    content: "Varor i varukorgen gör detta leveransdatum otillgängligt.";
    color: black;
}
.disabled-delivery:hover::before{
    display:block;
}
#main-navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 300;
}
.substitute-height{
    height:224px;
}
.price-marker {
    color: #610101;
}
.price-marker-promotional-items {
    color: #ff0000;
    font-weight: bold;
}
.price-marker-tag-box {
    position: relative;
}
.price-marker-tag {
    background-color: #ff0000;
    font-weight: 700;
    font-size: .75rem;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}
.card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.sticky {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 299;
}
.table-wrapper {
    overflow: auto;
    max-height: 800px;
}
@media (min-width: 768px) {
    .fixed-modal .modal-dialog {
        width: 600px;
        margin: 30px auto;
   }
}
@media (min-width: 992px) {
    .fixed-modal .modal-lg {
        width: 900px;
   }
}
@media (min-width: 768px) {
    .fixed-modal .modal-xl {
        width: 90%;
        max-width:1200px;
   }
}
@media (min-width: 1200px) {
    .substitute-height{
        height:121px;
   }
}
@media (max-width: 720px) {
    .substitute-height{
        height:266px;
   }
}
/* @media screen and (min-width: 960px) {
    .col-xl-10 {
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    } 
}
@media screen and (max-width: 1028px) {
    .col-xl-10 {
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    } 
} */
@media screen and (max-width: 960px) {
    table.order-table {
        display: block;
   }
    table.order-table thead {
        display: none;
   }
    table.order-table tbody {
        display: block;
   }
    table.order-table tr {
        display: block;
   }
    table.order-table td {
        display: inline-block;
        width: 100%;
        max-width: 100%;
        text-align: center;
   }
    table>tbody>tr>td:nth-child(1)>div {
        height: 12rem !important;
        width: 12rem !important;
        text-align: center;
        display: block;
        margin: 0 auto;
   }
    table>tbody>tr>td:nth-child(1)>div img {
        height: 12rem !important;
        width: 12rem !important;
   }
    .table-striped tbody tr:nth-of-type(odd) {
        background: #fff;
        border-bottom: 16px solid #f3f3f3;
   }
    .table-striped tbody tr:nth-of-type(even) {
        background: #fff;
        border-bottom: 16px solid #f3f3f3;
   }
    .order-table i.icon-custom-round::before {
        content: "Avtalsartikel";
        color: #212529;
        font-size: 1rem;
        position: relative;
        right: 97px;
   }
    .order-table .icon-custom-round.icon-deny {
        position: relative;
        left: 50px;
   }
    table.order-table td:nth-child(5) {
        font-weight: 600;
   }
    table.order-table td:nth-child(5)::before {
        content: "Kolli stl.";
        color: #212529;
        font-size: 1rem;
        position: relative;
        right: 2px;
        font-weight: 400;
   }
    table.order-table td:nth-child(6) {
        font-weight: 600;
   }
    table.order-table td:nth-child(6)::before {
        content: "Pris (SEK):";
        color: #212529;
        font-size: 1rem;
        position: relative;
        right: 2px;
        font-weight: 400;
   }
    .top-navbar .nav>.nav-item>.nav-link {
        padding: .2rem !important;
        font-size: 0.8rem !important;
   }
    .border {
        border: 0 !important;
   }
    .btn:not(:disabled):not(.disabled) {
        margin-bottom: 5px;
   }
    .navbar-brand {
        display: none;
   }
    .show-cart {
        display: none;
   }
    .substitute-height {
        height: auto;
   }
    .navbar {
        -webkit-justify-content: space-between;
        justify-content: space-around;
        background: #eee;
   }
    .cover-height {
        position: relative !important;
        top: 160px !important;
   }
    .py-5 {
        margin-top: 180px;
   }
}
@media (min-width: 768px) {
    .header-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100vh / 5.4);
        padding: 0;
   }
    .header-content {
        word-break: break-word;
        height: 100%;
        display:flex;
        align-items:center;
        justify-content:center;
        text-align: center;
        padding: 0.9rem;
   }
}
.swal2-html-container {
    font-size: 1rem !important;
}
.swal2-popup {
    width: 740px !important;
}
@media screen and (max-width: 960px) {
    .swal2-popup{
        width: auto !important;
   }
    .swal2-html-container>table>tbody>tr>td:nth-child(1)>div {
        height: 100% !important;
        width: 100% !important;
        text-align: center;
        display: block;
        margin: 0 auto;
   }
    .swal2-html-container>table.order-table td:nth-child(2)::before {
        content: "Pris" !important;
   }
    .swal2-html-container>table.order-table td:nth-child(3)::before {
        content: "Totalt" !important;
   }
    .swal2-html-container>table.order-table td:nth-child(4)::before {
        content: "Nytt Pris" !important;
   }
    .swal2-html-container>table.order-table td:nth-child(5) {
        font-weight: normal!important;
   }
    .swal2-html-container>table.order-table td:nth-child(5)::before {
        content: "Nytt Totalt" !important;
   }
    .swal2-html-container>.table-striped tbody tr:nth-of-type(odd) {
        background: #fff;
        border-bottom: 4px solid #f3f3f3;
   }
    .swal2-html-container>.table-striped tbody tr:nth-of-type(even) {
        background: #fff;
        border-bottom: 4px solid #f3f3f3;
   }
}
